import { IEndpoint, Auth, JsonRequest, JsonResponse } from './_endpoint.interface';
export { Auth, JsonRequest, JsonResponse }

class Callbacks<ResponseType extends JsonResponse> { 
  onResponse: (response: ResponseType) => void;
  onError: (code: number, message: string) => void;

  constructor(onResponse: (response: any) => void, onError: (code: number, message: string) => void) {
    this.onResponse = onResponse;
    this.onError = onError;
  }
}

export class JsonEndpoint implements IEndpoint {
  private requestMap = new Map<string, Callbacks<JsonResponse>[]>;
  private auth: Auth|null;
  protected endpoint: string;

  public constructor(auth: Auth|null, endpoint: string) {
    this.auth = auth;
    this.endpoint = endpoint;
  }

  public getRequest<RequestType extends JsonRequest, ResponseType extends JsonResponse>(request: RequestType,
      responseClass: new(json: any) => ResponseType, onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void) {
    const url = this.endpoint + '?' + Object.keys(request).filter(k => request[k] != null).map(k => k + '=' + encodeURIComponent(request[k].toString())).join('&');
    const httpRequest = {method: 'GET', headers: { 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json', 'Authorization': this.auth?.getAuthorization() /*, 'Token': this.auth?.getToken()*/ }};
    this.sendHttpRequest(url, httpRequest, responseClass, onResponse, onError);
  }

  public postRequest<RequestType extends JsonRequest, ResponseType extends JsonResponse>(request: RequestType,
      responseClass: new(json: any) => ResponseType, onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void) {
    const httpRequest = {method: 'POST', body: request.json, headers: { 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json', 'Authorization': this.auth?.getAuthorization(), 'Token': this.auth?.getToken() }};
    this.sendHttpRequest(this.endpoint, httpRequest, responseClass, onResponse, onError);
  }

  private sendHttpRequest<RequestType extends JsonRequest, ResponseType extends JsonResponse>(url:string, httpRequest: any,
      responseClass: new(json: any) => ResponseType, onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void) {
    const id = url + '|' + httpRequest.body;
    const callbacks = this.requestMap.get(id);
    if (callbacks == undefined) {
      this.requestMap.set(id, [new Callbacks(onResponse, onError)]);
      fetch(url, httpRequest)
        .then((response) => {
          const callbacks = this.requestMap.get(id);
          this.requestMap.delete(id);
          if (callbacks == undefined) {
            console.error(`JsonResponse received for ${id} but no callback is registered`);
          }
          else if (response.ok) {
            response.text().then(s => {
              try {
                const jsonResponse = JSON.parse(s);
                callbacks.filter(c => c.onResponse != null).forEach(c => c.onResponse(new responseClass(jsonResponse)));
              }
              catch (e) {
                console.error(`JsonRequest >>${id}<< produced a response >>${s}<< that could not be parsed.`);
              }
            });
          }
          else {
            console.error(`JsonRequest ${id} returned ERROR ${response.status}: ${response.statusText}`);
            response.text().then(s => callbacks.filter(c => c.onError != null).forEach(c => c.onError(response.status, JSON.parse(s)['message'])));
          }
        })
        .catch((error) => {
          this.requestMap.delete(id);
          console.error(`Could not fetch request ${id} due to ERROR ${error}`);
        });
    }
    else {
      // Insert request into the list of callbacks, response will be sent once current request is returned
      callbacks.push(new Callbacks(onResponse, onError));      
    }
  }
}
