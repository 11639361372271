import { Component, forwardRef, Input, Output, AfterViewInit, EventEmitter, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkDrag, CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';

export class LbtNumberInterval {
  public min: number;
  public max: number;

  constructor(min: number, max: number) {
    this.min = Math.min(min, max);
    this.max = Math.max(min, max);
  }
}

@Component({
  selector: 'lbt-interval',
  templateUrl: './lbt-interval.component.html',
  styleUrl: './lbt-interval.component.css',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LbtIntervalComponent), multi: true}],
  host: {'[class.error]': 'error', '[class.disabled]': 'disabled', '[class.focused]': 'focused', '[class.empty]': '!input'}
})
export class LbtIntervalComponent implements AfterViewInit, ControlValueAccessor {
  @Input() protected disabled = false;
  @Input() protected type: string;
  @Input() protected hint: string;
  @Input() protected placeholder: string;
  @Input() protected error: boolean;
  @Input() protected errorMessage: string;
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();
  @ViewChild('start') startElement: CdkDrag;
  @ViewChild('end') endElement: CdkDrag;
  @ViewChild('selection') selectionBar: ElementRef;
  protected focused = false;
  private _text = false;
  protected get text():boolean { return this._text; }
  protected set text(text: boolean) { this._text = text; setTimeout(_ => this.updatePositions()); }

  constructor(private readonly viewRef: ViewContainerRef) {}

  public ngAfterViewInit(): void {
    this.updatePositions();
  }

  private updatePositions() {
    const parentWidth = this.viewRef.element.nativeElement.offsetWidth;
    if (this.startElement && this.endElement) {
      const startWidth = this.startElement['nativeElement'].offsetWidth;
      const endWidth = this.endElement['nativeElement'].offsetWidth;

      let start = 0;
      let end = 0;
      if (this._values?.length > 0) {
        start = (this._values.findIndex(v => v >= this.currentStart) / (this._values.length - 1)) * (parentWidth - startWidth);
        end = (this._values.findIndex(v => v >= this.currentEnd) / (this._values.length - 1)) * (parentWidth - endWidth);
      } 
      else if (this._limits) {
        start = (this.currentStart - this._limits.min) / (this._limits.max - this._limits.min) *  (parentWidth - startWidth);
        end = (this.currentEnd - this._limits.min) / (this._limits.max - this._limits.min) *  (parentWidth - endWidth);
      }
      setTimeout(_ => {this.currentStartPx = start; this.currentEndPx = end;});
    }
  }

  protected onFocusInternal(event: Event, focus: boolean) {
    if (focus != this.focused) {
      this.focused = focus;
      focus ? this.focus.emit() : this.focusout.emit();
      if (focus == false && event.target instanceof HTMLInputElement)
      {
        if (event.target.id == "start") {
          this.currentStart = this.parser(event.target.value);
        }
        else if (event.target.id == "end")
        {
          this.currentEnd = this.parser(event.target.value);
        }
        this.updateInterval();
      }
    }
  }

  private _limits: LbtNumberInterval;
  @Input() set limits(limits: LbtNumberInterval) {
    this._limits = limits;
    this.updatePositions();
  };

  private _values: number[] = [];
  @Input() set values(values: number[]) {
    this._limits = new LbtNumberInterval(0, values.length - 1);
    this._values = values.sort((v1, v2) => v1 - v2);
    this.updatePositions();
  }

  @Input() formatter: (number) => string;
  @Input() parser: (string) => number;

  protected currentStart: number = -Number.MAX_VALUE; // value
  protected currentEnd: number = Number.MAX_VALUE;   // value

  private previousStartPx: number = 0; // pixels
  private previousEndPx: number = 0; // pixels
  protected currentStartPx: number = 0; // pixels
  protected currentEndPx: number = 0;   // pixels
 
  protected get leftOffset(): number {
    const parentWidth = this.viewRef.element.nativeElement.offsetWidth;
    return this.currentStart <= this.currentEnd ? this.currentStartPx : this.currentEndPx;
  }
  
  protected get rightOffset(): number {
    const parentWidth = this.viewRef.element.nativeElement.offsetWidth;
    return parentWidth - (this.currentEnd >= this.currentStart ? this.currentEndPx : this.currentStartPx);
  }

  protected format(value: number): string {
    return this.formatter ? this.formatter(value) : value.toString();
  }

  protected parse(value: string): number {
    return this.parser ? this.parser(value) : Number(value);
  }
  
  protected onStartStarted(cdk: CdkDragStart) {
    this.previousStartPx = this.currentStartPx;
  }

  protected onStartMoved(cdk: CdkDragMove) {
    const width = this.viewRef.element.nativeElement.offsetWidth - this.startElement['nativeElement'].offsetWidth;
    this.currentStartPx = this.previousStartPx + cdk.distance.x;
    if (this._values) {
      this.currentStart = this._values[Math.round(this.currentStartPx / width * (this._values.length - 1))];
    }
    else {
      this.currentStart = this._limits.min + Math.round((this._limits.min + this.currentStartPx) / width * (this._limits.max - this._limits.min));
    }
  }
  
  protected onStartEnded(cdk: CdkDragEnd) {
    this.previousStartPx = 0;
    this.updateInterval();
  }

  protected onEndStarted(cdk: CdkDragStart) {
    this.previousEndPx = this.currentEndPx;
  }

  protected onEndMoved(cdk: CdkDragMove) {
    const width = this.viewRef.element.nativeElement.offsetWidth - this.endElement['nativeElement'].offsetWidth;
    this.currentEndPx = this.previousEndPx + cdk.distance.x;
    if (this._values) {
      this.currentEnd = this._values[Math.round(this.currentEndPx / width * (this._values.length - 1))];
    }
    else {
      this.currentEnd = this._limits.min + Math.round((this._limits.min + this.currentEndPx) / width * (this._limits.max - this._limits.min));
    }
  }

  protected onEndEnded(cdk: CdkDragEnd) {
    this.previousEndPx = 0;
    this.updateInterval();
  }

  private updateInterval() {
    this.input = new LbtNumberInterval(Math.min(this.currentStart, this.currentEnd) , Math.max(this.currentStart, this.currentEnd));
  }

  /**** input var ****/
  private _input: LbtNumberInterval;
  protected set input(value: LbtNumberInterval) {
    if (this._input.min !== value.min || this._input.max !== value.max) {
      this._input.min = value.min;
      this._input.max = value.max;
      this.onChange(this._input);
      this.onTouch(this._input);
    }
  }

  protected get input(): LbtNumberInterval {
    return this._input;
  }
  
  /**** ngModel ****/
  protected onChange: any = () => {};
  protected onTouch: any = () => {};

  public writeValue(value: any) {
    this.currentStart = value ? value.min : this._limits.min;
    this.currentEnd = value ? value.max : this._limits.max;
    this._input = new LbtNumberInterval(this.currentStart, this.currentEnd);
    this.updatePositions();
  }
  
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
