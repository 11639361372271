import { JsonEndpoint, JsonRequest, JsonResponse, Auth } from './jsonendpoint';
import { JWT } from '../jwt/jwt.helper';
import { Base64Url } from '../utils/base64Utils';
import { HashUtils } from '../utils/hashUtils';

export class ElderbarRequest extends JsonRequest {
  private type: string;
  private lang: string|null;

  constructor(type: string, lang: string|null = null) {
    super();
    this.type = type;
    this.lang = lang;
  }
}

export class UniqueRequest extends ElderbarRequest {
  private uuid: string; // Add uuid to avoid collapsing into one identical requests, like addCommand for cover fees.

  constructor(type: string) {
    super(type);
    this.uuid = crypto.randomUUID();
  }
}

export class ElderbarResponse extends JsonResponse {
  constructor(json: any) {
    super(json);
  }
}

export class SuccessResponse extends JsonResponse {
  success: boolean;

  constructor(json: any) {
    super(json);
    this.success = json['success'];
  }
}

const XOR = [0xcc, 0xc5, 0x5c, 0x55];
const NUM_TS = 12;

class EndpointAuthorization implements Auth {
  private static jwt = new JWT('elderbar.token');
  private token: string|null;
  private pt:Uint8Array|null = null;

  public getAuthorization(): string|null {
    return EndpointAuthorization.jwt.hasTokens() ? 'Bearer ' + EndpointAuthorization.jwt.getActiveToken() : '';
  }

  // TODO: Move token generation to a WebEndpoint class
  public getToken(): string|null {
    return this.token;
  }

  public getJwt(): JWT {
    return EndpointAuthorization.jwt;
  }

  public next(data: string) {
    this.token = this.gpt(data);
  }

  public s() {
    this.pt = crypto.getRandomValues(new Uint8Array(NUM_TS << 1));
  }

  public c() {
    this.pt = null;
  }

  public t(i: number, ts: number) {
    if (this.pt != null && i < NUM_TS) {
      i += NUM_TS;
      this.pt[i] = 0;
      for (let t = ts; t > 1; t = Math.round(t/10)) {
        this.pt[i] = (this.pt[i] & 0xe0) + 0x20 + t % 11;
      }
    }
  }

  // this builds a security token that will be parsed in the server according to each request definition
  // this is meant to protect public API to be spammed, it will allow, deny or request additional input depending on its validity score
  private gpt(d: string) {
    if (this.pt != null) {
      const currentPt = this.pt;
      let pt = Base64Url.byteEncode(currentPt.slice(0, NUM_TS)) + '.' + Base64Url.byteEncode(currentPt.slice(NUM_TS).map((b, i) => b ^ currentPt[i] ^ XOR[i%XOR.length])) + '.';
      return pt + HashUtils.cyrb53(d + pt);
    }
    return null;
  }
}

export class ElderbarEndpoint extends JsonEndpoint {
  private static auth = new EndpointAuthorization();

  constructor(endpoint) {
    super(ElderbarEndpoint.auth, endpoint);
  }

  public sendRequest<RequestType extends ElderbarRequest, ResponseType extends ElderbarResponse>(request: RequestType, responseClass: new(json: any[]) => ResponseType, 
      onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void) {
    ElderbarEndpoint.auth.next(request.json);
    super.postRequest(request, responseClass, onResponse, 
      (c, m) => { 
        if (c == 401) { // Unauthorized
          ElderbarEndpoint.auth.getJwt().clearActiveToken();
        }
        onError(c, m);
      });
  }

  protected s() {
    ElderbarEndpoint.auth.s();
  }

  protected c() {
    ElderbarEndpoint.auth.c();
  }

  protected t(i: number, ts: number) {
    ElderbarEndpoint.auth.t(i, ts);
  }

  public subscribeToToken(onTokenUpdated: (token: any) => void) {
     return ElderbarEndpoint.auth.getJwt().subscribeToToken(onTokenUpdated);
  }

  public storeToken(token: string, idPath: string[]): boolean {
    return ElderbarEndpoint.auth.getJwt().storeToken(token, idPath);
  }

  public getActiveToken(): string {
    return ElderbarEndpoint.auth.getJwt().getActiveToken();
  }

  public getActiveTokenHeader(): any {
    return ElderbarEndpoint.auth.getJwt().getActiveTokenHeader();
  }

  public getActiveTokenPayload(): any {
    return ElderbarEndpoint.auth.getJwt().getActiveTokenPayload();
  }

  public getActiveTokenSignature(): any {
    return ElderbarEndpoint.auth.getJwt().getActiveTokenSignature();
  }

  public clearActiveToken(): void {
    ElderbarEndpoint.auth.getJwt().clearActiveToken();
  }

  public getUserPermission(): string {    
    const token = this.getActiveTokenPayload();
    return token ? token['permission']['role'] : null;
  }

  public getLoginType(): string {
    const token = this.getActiveTokenPayload();
    return token ? token['session']['type'] : null;
  }

}
