import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from './shared/dialog/dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements AfterViewInit {
  @ViewChild(DialogComponent) dialog: DialogComponent;

  constructor(private router: Router) {}

  public ngAfterViewInit(): void {
    DialogComponent.registerDialog(this.dialog);
  }
}
