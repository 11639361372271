import { LbtSelectValue } from '../forms/lbt-select/lbt-select.component';
import { DialogInput } from './dialog.input';

export class OptionValue {
  value: LbtSelectValue;
  cascadeValues: OptionValue[]|null;
  cascadeDefault: string|number|null;

  constructor(id: number, text: string, cascadeValues: OptionValue[]|null = null, cascadeDefault: string|number|null = null) {
    this.value = new LbtSelectValue(id, text);
    this.cascadeValues = cascadeValues;
    this.cascadeDefault = cascadeDefault;
  }
}

export class DialogOptionInput implements DialogInput {
  protected next: DialogOptionInput|null;
  
  constructor(name: string, values: OptionValue[], selected = null, required: boolean = true) {
    this._name = name;
    this._values = values;
    this._selected = selected;
    this._required = required;
    this.next = null;
  }
  
  private _name: string;
  public get name(): string {
    return this._name;
  }
  
  private _values: OptionValue[];
  public get values(): LbtSelectValue[] {
    return this._values.map(v => v.value);
  }

  private _required: boolean;

  public setNext(option: DialogOptionInput|null) {
    this.next = option;
  }
  
  private setNewValues(values: OptionValue[]) {
    this._values = values;
    if (values.findIndex(v => v.value.id == this.selected) < 0) {
      this.selected = this._values.length == 1 ? this._values[0].value.id : null;
    }
    else {
      this.selected = this.selected; // force the set so cascade values get propagated if those have changed
    }
  }
  
  private _selected: string|number|null;
  public set selected(selected: string|number|null) {
    this._selected = selected;
    const v = this._values.find(v => v.value.id == selected);
    if (this.next != null && v != undefined && v.cascadeValues != null) {
      this.next.setNewValues(v.cascadeValues);
      this.next.selected = v.cascadeDefault;
    }
  }

  public get selected(): string|number|null {
    return this._selected;
  }

  public isValid(): boolean {
    return !this._required || this._selected != null;
  }

  public isRequired(): boolean {
    return this._required;
  }
}
