@if (dialogShow) {
<div class="box {{dialogStyle}}">
  <span class="title">{{ dialogTitle }}</span>
  <span class="message" [innerHtml]="dialogMessage | safeHtml"></span>
@for (input of dialogInputs; track input) {
@if (getTextInput(input); as i) {
  <lbt-input
    [class]="i.name"
    placeholder="{{i.name}}"
    [(ngModel)]="i.value"
    [error]="i.value != '' && !i.isValid()"
    errorMessage="{{i.error}}">
  </lbt-input>
}
@if (getOptionInput(input); as i) {
  <lbt-select 
    placeholder="{{i.name}}"
    [(ngModel)]="i.selected" 
    [values]="i.values">
  </lbt-select>
}
@if (getIntervalInput(input); as i) {
  <lbt-interval
    placeholder="{{i.name}}"
    [values]="i.values"
    [(ngModel)]="i.interval"
    [formatter]="i.formatter"
    [parser]="i.parser">
  </lbt-interval>
}
}
@for (accept of acceptButtons; track accept) {
  <lbt-button class="accept"
      [disabled]="!isValid()"
      (click)="acceptDialog(accept)">{{ accept }}</lbt-button>
}
@for (cancel of cancelButtons; track accept) {
  <lbt-button class="cancel"
      (click)="cancelDialog(cancel)">{{ cancel }}</lbt-button>
}
</div>
}