import { DialogInput } from './dialog.input';

export class DialogTextInput implements DialogInput {
  private _name: string;
  public value: string;
  private regex: RegExp;
  private _required: boolean;
  private _error: string;

  constructor(name: string, validRegex:RegExp = /.*/, errMessage: string = '', startValue: string = '', required: boolean = true) {
    this._name = name;
    this.value = startValue;
    this.regex = validRegex;
    this._required = required;
    this._error = errMessage;
  }

  public get name(): string {
    return this._name;
  }

  public get error(): string {
    return this._error;
  }

  public isValid(): boolean {
    return (this.value.trim().match(this.regex)) ? true : false;
  }

  public isRequired(): boolean {
    return this._required;
  }
}