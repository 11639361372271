interface AndroidApi {
  getDeviceId(): string; // DEPRECATED
  getAndroidDeviceId(): string;
}

declare var Android: AndroidApi;

import { Component, OnInit } from '@angular/core';

import { LoginService } from '../login.service';
import { DateUtils } from '../shared/utils/dateUtils';

enum Mode {
  PinLogin = 0,
  PsswdLogin
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  private ls = LoginService.getInstance();

  protected Mode = Mode; // Make Mode enum accessible from html
  protected mode = Mode.PinLogin;
  protected user = '';
  protected password = '';
  protected error = '';
  protected hasNfc = false; // TODO: get this from AndroidApi
  protected users = [];
  protected shuffle = 0;
  protected code = '';
  //private deviceId = typeof Android !== 'undefined' && typeof Android.getAndroidDeviceId === 'function' ? Android.getAndroidDeviceId() : null;
  private deviceId = 'ac2a61351242753a';
  private oldValue = '';

  public ngOnInit() {
    switch (this.ls.getRole()) {
    case 'client':
    case 'employee':
    case 'admin':
      window.location.assign('/tpv');
      break;
    default:
      this.clearForm();
      if (this.deviceId != null) {
        this.mode = Mode.PinLogin;
        this.shuffle = Math.random();
        // get users allowed to login
        this.ls.getDeviceUsers(this.deviceId, DateUtils.getTodayString(), u => this.users = u);
      } 
      else {
        this.mode = Mode.PsswdLogin;
      }
      break;
    }
  }

  protected focus(event: any): void {
    this.oldValue = event.target.value;
  }

  protected change(event: any): void {
    if (this.oldValue === event.target.value) {
      if (event.target.nextElementSibling.select) {
        event.target.nextElementSibling.select();
      } else {
        this.login(null, this.user, this.password);
      }
    }
  }

  protected clearCode(event: string): void {
    if (this.code != "") {
      this.clearForm();
    }
    else if (event != null) {
      window.location.reload();
    }
  }

  protected nfcLogin(nfcId: string): void {
    this.login(nfcId);
  }

  protected pinLogin(user: string): void {
    this.login(null, this.code, user)
  }

  protected passwordLogin(user: string, password: string): void {
    this.login(null, null, user, password);
  }

  private login(nfcId: string = null, pin: string = null, user: string = null, password: string = null): void {
    this.ls.login(null, this.deviceId, user, password, +pin, nfcId, _r => {}, (_, m) => this.clearForm(m));
    this.clearForm();
  }

  private clearForm(error: string = ''): void {
    // don't clear user
    this.password = '';
    this.error = error;
    this.code = '';
    this.shuffle = Math.random();
  }
}
