<span class="placeholder">{{placeholder}}<span class="errorMessage"> ({{errorMessage}})</span></span>
@if (type != 'multiline') {
  <input [name]="hint"
      [type]="type ? type : 'text'"
      [placeholder]="hint ? hint : ' '"
      [disabled]="disabled"
      [(ngModel)]="input"
      (focus)="onFocusInternal(true)"
      (focusout)="onFocusInternal(false)" 
      autocomplete="off"
      x-autocompletetype="off" />
} @else {
  <textarea [name]="hint"
      [placeholder]="hint ? hint : ' '"
      [disabled]="disabled"
      [(ngModel)]="input"
      (focus)="onFocusInternal(true)"
      (focusout)="onFocusInternal(false)" 
      (scroll)="scrollTop($event)"
      (keyup)="onKeyUpInternal($event)"
      autocomplete="off"
      x-autocompletetype="off">
  </textarea>
}
<div class="underline"
    [class.active]="input != ''">
  <span class="active"></span>
@if (strength > 0) {
  <span class="strength" [class.red]="input != ''"></span>
  <span class="strength" [class.yellow]="strength > 1"></span>
  <span class="strength" [class.lightgreen]="strength > 2"></span>
  <span class="strength" [class.green]="strength > 3"></span>
}
</div>
