import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lbt-pin',
  templateUrl: './lbt-pin.component.html',
  styleUrl: './lbt-pin.component.css',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LbtPinComponent), multi: true}],
  host: {'[class.error]': 'error', '[class.disabled]': 'disabled', '[class.focused]': 'focused', '[class.empty]': '!input'}
})
export class LbtPinComponent implements ControlValueAccessor {
  @Input() protected disabled = false;
  @Input() protected error: boolean;
  @Input() protected errorMessage: string;
  @Input() protected extraKey: string = 'C';
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();
  @Output() onExtraKey = new EventEmitter();
  protected focused = false;
  protected keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  public constructor() {
    this.shuffleKeys = 0;
  }

  protected onFocusInternal(focus: boolean) {
    if (focus != this.focused) {
      this.focused = focus;
      focus ? this.focus.emit() : this.focusout.emit();
    }
  }

  protected onKeyUpInternal(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    target.style.height = "1em";
    target.style.maxHeight = "0";
    target.style.height = target.scrollHeight + "px";
    target.style.maxHeight = target.scrollHeight + "px";
  }

  protected onKey(key: number): void {
    this.input += key;
  }

  protected extraKeyPressed(key: string): void {
    this.onExtraKey.emit(key);
    // null this code after 100 ms, so if entered again we resend it
    setTimeout(() => this.onExtraKey.emit(null), 100);
  }

  @Input() set shuffleKeys(random: number) {
    for (let i = this.keys.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.keys[i], this.keys[j]] = [this.keys[j], this.keys[i]];
    }
  }

  /**** input var ****/
  private _input = '';
  protected set input(value: string) {
    if (this._input !== value) {
      this._input = value;
      this.onChange(value);
      this.onTouch(value);
    }
  }

  protected get input(): string {
    return this._input;
  }

  /**** ngModel ****/
  protected onChange: any = () => {};
  protected onTouch: any = () => {};

  public writeValue(value: any) {
    this._input = value;
  }
  
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
