import { LoginEndpoint, LoginRequest, LoginResponse, DeviceUsersRequest, CheckCodeRequest, UpdatePinRequest, UpdatePasswordRequest } from './login.endpoint';

export class LoginService {
  private static instance: LoginService;

  private endpoint: LoginEndpoint;

  public static getInstance(): LoginService {
    if (!LoginService.instance) {
      LoginService.instance = new LoginService();
    }
    return LoginService.instance;
  }

  constructor() {
    console.log('initiating login service');
    this.endpoint = new LoginEndpoint();
  }

  public subscribeToToken(onTokenUpdated: (token: any) => void) {
    return this.endpoint.subscribeToToken(onTokenUpdated);
  }


  public login(storeId: number, deviceId: string, email: string, password: string, pin: number, nfcId: string,
        onSuccess: (response: LoginResponse) => void, onError: (code: number, message: string) => void) {
    this.endpoint.login(new LoginRequest(storeId, deviceId, email, password, pin, nfcId),
    r => {
      if (this.endpoint.storeToken(r.auth_token, ['employee', 'id'])) {
        document.cookie = "userId=" + this.endpoint.getActiveTokenSignature() + "; secure; samesite";
        window.location.assign('/tpv');
      }
      onSuccess(r);
    },
    (c, m) => onError(c, m));
  }

  public getDeviceUsers(deviceId: string, date: string, onSuccess: (users: any[]) => void) {
    this.endpoint.getDeviceUsers(new DeviceUsersRequest(deviceId, date), r => onSuccess(r.users), _e => {});
  }

  public getRole(): any {
    const token = this.endpoint.getActiveTokenPayload();
    return token && token['permission'] ? token['permission']['role'] : null;
  }

  public checkCode(code: string, onSuccess: (name: string, isPin: boolean) => void, onError: () => void = null) {
    this.endpoint.checkCode(new CheckCodeRequest(code), r => onSuccess(r.name, r.isPin), _ => onError ? onError() : null);
  }

  public updatePin(code: string, newPin: number, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.updatePin(new UpdatePinRequest(code, newPin), () => onSuccess(), _ => onError ? onError() : null);
  }

  public updatePassword(code: string, newPassword: string, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.updatePassword(new UpdatePasswordRequest(code, newPassword), () => onSuccess(), _ => onError ? onError() : null);
  }
}
