import { ElderbarEndpoint, ElderbarRequest, ElderbarResponse, SuccessResponse } from './shared/endpoint/elderbarendpoint';


export class CheckCodeRequest extends ElderbarRequest {
  code: string;

  constructor(code: string) {
    super('checkCode');
    this.code = code;
  }
}

export class UpdatePinRequest extends ElderbarRequest {
  code: string;
  pin: number;
  
  constructor(code: string, pin: number) {
    super('updatePin');
    this.code = code;
    this.pin = pin;
  }
}

export class UpdatePasswordRequest extends ElderbarRequest {
  code: string;
  password: string;
  
  constructor(code: string, password: string) {
    super('updatePassword');
    this.code = code;
    this.password = password;
  }
}

export class LoginRequest extends ElderbarRequest {
  storeId: number;
  devId: string;
  user: string;
  password: string;
  pin: number;
  nfcId: string;

  constructor(storeId: number, deviceId: string, email: string, password: string, pin: number, nfcId: string) {
    super('login');
    this.storeId = storeId;
    this.devId = deviceId;
    this.user = email;
    this.password = password;
    this.pin = pin;
    this.nfcId = nfcId;
  }
}

export class CodeResponse extends ElderbarResponse {
  name: string;
  isPin: boolean;

  constructor(json: any[]) {
    super(json);
    this.name = json['name'];
    this.isPin = json['isPin'] == '1';
  }
}

export class LoginResponse extends ElderbarResponse {
  auth_token: string;
  stores: any[];

  constructor(json: any[]) {
    super(json);
    this.auth_token = json['auth_token'];
    this.stores = [];
    for (const i in json['stores']) { // tslint:disable-line:forin
      this.stores.push(json['stores'][i]);
    }
  }
}

export class DeviceUsersRequest extends ElderbarRequest {
  deviceId: string;
  date: string;

  constructor(deviceId: string, date: string) {
    super('getDeviceEmployees');
    this.deviceId = deviceId;
    this.date = date;
  }
}

export class DeviceUsersResponse extends ElderbarResponse {
  users: any[];

  constructor(json: any[]) {
    super(json);
    this.users = json['users'];
  }
}


export class LoginEndpoint extends ElderbarEndpoint {

  constructor() {
    super('/api/v3/employees/');
  }

  public checkCode(request: CheckCodeRequest, onResponse: (response: CodeResponse) => void, onError: (code: number, message: string) => void = null) {
    super.sendRequest<CheckCodeRequest, CodeResponse>(request, CodeResponse, onResponse, onError);
  }

  public updatePin(request: UpdatePinRequest, onResponse: (response: SuccessResponse) => void, onError: (code: number, message: string) => void = null) {
    super.sendRequest<UpdatePinRequest, SuccessResponse>(request, SuccessResponse, onResponse, onError);
  }

  public updatePassword(request: UpdatePasswordRequest, onResponse: (response: SuccessResponse) => void, onError: (code: number, message: string) => void = null) {
    super.sendRequest<UpdatePasswordRequest, SuccessResponse>(request, SuccessResponse, onResponse, onError);
  }

  public login(request: LoginRequest, onResponse: (response: LoginResponse) => void, onError: (code: number, message: string) => void = null) {
    super.sendRequest<LoginRequest, LoginResponse>(request, LoginResponse, onResponse, onError);
  }

  public getDeviceUsers(request: DeviceUsersRequest, onResponse: (response: DeviceUsersResponse) => void, onError: (code: number, message: string) => void = null) {
    super.sendRequest<DeviceUsersRequest, DeviceUsersResponse>(request, DeviceUsersResponse, onResponse, onError);
  }
}
