<div class="keys">
@for (key of keys; track key) {
  <lbt-button class="number"
      (click)="onKey(key)">
    {{ key }}
  </lbt-button>
}
  <lbt-button class="extra"
      (click)="extraKeyPressed(extraKey)">
    {{ extraKey }}
  </lbt-button>
</div>
<span class="errorMessage">{{errorMessage}}</span>

