<span class="placeholder">{{placeholder}}<span class="errorMessage"> ({{errorMessage}})</span></span>
@if (text) {
<input id="start" 
    [disabled]="disabled"
    [ngModel]="format(currentStart)"
    (focus)="onFocusInternal($event, true)"
    (focusout)="onFocusInternal($event, false)" 
    autocomplete="off"
    x-autocompletetype="off" />
<span class="separator" (click)="text = !text">-</span>
<input id="end" 
    [placeholder]="hint ? hint : ' '"
    [ngModel]="format(currentEnd)"
    (focus)="onFocusInternal($event, true)"
    (focusout)="onFocusInternal($event, false)" 
    autocomplete="off"
    x-autocompletetype="off" /> 
}
@else {
<div class="boundary">
  <div class="background-bar" (click)="text = !text"></div>
  <div #selection class="selected-bar"
      (click)="text = !text"
      [style.left]="leftOffset + 'px'"
      [style.right]="rightOffset + 'px'">
  </div>
  <div #end class="end"
      [style.transform]="'translateX(' + currentEndPx + 'px)'"
      cdkDrag
      cdkDragLockAxis="x"
      cdkDragBoundary=".boundary"
      (cdkDragStarted)="onEndStarted($event)"
      (cdkDragMoved)="onEndMoved($event)"
      (cdkDragEnded)="onEndEnded($event)">
    <span>{{format(currentEnd)}}</span>
  </div>
  <div #start class="start"
      [style.transform]="'translateX(' + currentStartPx + 'px)'"
      cdkDrag
      cdkDragLockAxis="x"
      cdkDragBoundary=".boundary"
      (cdkDragStarted)="onStartStarted($event)"
      (cdkDragMoved)="onStartMoved($event)"
      (cdkDragEnded)="onStartEnded($event)">
    <span>{{format(currentStart)}}</span>
  </div>
</div>
}