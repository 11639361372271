//  Inspired on Base64 encode/decode from http://www.webtoolkit.info

export class Base64Url {

  private static _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=";
  
  public static utf8Encode(input: string): string {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    input = this._utf8Encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      }
      else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output += this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
          this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
    }
    return output;
  }

  public static utf8Decode(input: string): string
  {
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;

    input = input.replace(/[^A-Za-z0-9\-_]/g, "");
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = i < input.length ? this._keyStr.indexOf(input.charAt(i++)) : 64;
      enc4 = i < input.length ? this._keyStr.indexOf(input.charAt(i++)) : 64;

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
      }
    }
    return this._utf8Decode(output);
  }

  public static byteEncode(input: Uint8Array): string {
    var output = "";
    var b1, b2, b3;
    var i = 0;

    while (i < input.length) {
      b1 = input[i++];
      b2 = i < input.length ? input[i++] : null;
      b3 = i < input.length ? input[i++] : null;

      output += this._keyStr.charAt(b1 >> 2);
      output += this._keyStr.charAt(((b1 & 3) << 4) | (b2 >> 4));
      if (b2) {
        output += this._keyStr.charAt(((b2 & 15) << 2) | (b3 >> 6));
      }
      if (b3) {
        output += this._keyStr.charAt(b3 & 63);
      }
    }
    return output;
  }

  public static byteDecode(input: string): Uint8Array
  {
    input = input.replace(/[^A-Za-z0-9\-_]/g, "");
    var output = new Uint8Array(Math.floor(input.length*3/4 + Number.EPSILON));
    var enc1, enc2, enc3, enc4;
    var i = 0, o = 0;
    
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      output[o++] = (enc1 << 2) | (enc2 >> 4);
      if (enc3 != 64) {
        output[o++] = ((enc2 & 15) << 4) | (enc3 >> 2);
      }
      if (enc4 != 64) {
        output[o++] = ((enc3 & 3) << 6) | enc4;
      }
    }
    return output;
  }

  private static _utf8Encode(string): string
  {
    var utftext = "";
    string = string.replace(/\r\n/g, "\n");

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);

      if (c < 128) {
          utftext += String.fromCharCode(c);
      }
      else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
      }
      else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  }

  private static _utf8Decode(utftext): string
  {
    var string = "";
    var i = 0;
    var c, c1, c2, c3;
    c = c1 = c2 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      }
      else if ((c > 191) && (c < 224)) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      }
      else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    }
    return string;
  }
}