export interface Auth {
  getAuthorization(): string|null;
  getToken(): string|null;
}

export class JsonRequest {
  get json(): string {
    return JSON.stringify(this);
  }
};

export class JsonResponse {
  constructor(json: any) {}
};

export interface IEndpoint {

  getRequest<RequestType extends JsonRequest, ResponseType extends JsonResponse>(request: RequestType,
    responseClass: new(json: any) => ResponseType, onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void);
  postRequest<RequestType extends JsonRequest, ResponseType extends JsonResponse>(request: RequestType,
    responseClass: new(json: any) => ResponseType, onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void);
}
