import { DialogInput } from './dialog.input';
import { LbtNumberInterval } from '../forms/lbt-interval/lbt-interval.component';

export { LbtNumberInterval }

export class DialogIntervalInput implements DialogInput {
  private _name: string;
  public values: number[];
  public interval: LbtNumberInterval;
  public formatter: ((number) => string)|null = null;
  public parser: ((string) => number)|null = null
  private regex: RegExp;
  private _required: boolean;
  private _error: string;

  constructor(name: string, values: number[], formatter: ((number) => string)|null = null, parser: ((string) => number)|null = null, interval: LbtNumberInterval|null = null, valueRegex:RegExp = /.*/, errMessage: string = '', required: boolean = true) {
    this._name = name;
    this.values = values;
    this.regex = valueRegex;
    this.formatter = formatter;
    this.parser = parser;
    this._required = required;
    this._error = errMessage;
    this.interval = interval ? interval : new LbtNumberInterval(values[0], values[values.length - 1]);
  }

  public get name(): string {
    return this._name;
  }

  public get error(): string {
    return this._error;
  }

  public get value(): string {
    return '';
  }
  
  public isValid(): boolean {
    return (this.value.trim().match(this.regex)) ? true : false;
  }

  public isRequired(): boolean {
    return this._required;
  }
}