import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../login.service';
import { Zxcvbn } from '../shared/zxcvbn/zxcvbn';
import { DialogComponent } from '../shared/dialog/dialog.component';

enum Mode {
  Loading = 0,
  PinLogin,
  PsswdLogin,
  None
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent implements OnInit {
  private ls = LoginService.getInstance();
  
  protected Mode = Mode; // Make Mode enum accessible from html
  protected mode: Mode;
  protected user = '';
  private code = '';
  protected message = '';
  protected button = '';
  protected shuffle = 0;

  constructor(private route: ActivatedRoute) {
    this.mode = Mode.Loading;
  }

  public ngOnInit(): void {
    // url param access
    this.route.queryParams.subscribe(
     params => { 
       const keys = Object.keys(params);
       if (keys.length == 1) {
         const code = keys[0];
         this.processCode(code);
       }
     }
   );
   // url path access
   const code = this.route.snapshot.paramMap.get('code');
   if (code != null) {
     this.processCode(code);
   }
   else {
     this.mode = Mode.None;
   }
 }

  private processCode(code: string) {
    console.log('processing code ' + code);
    if (code != null && code != '') {
      this.ls.checkCode(code,
        (name, isPin) => {
          this.code = code;
          this.user = name;
          this.mode = isPin ? Mode.PinLogin : Mode.PsswdLogin;
          if (isPin) {
            this.message = 'Hola ' + name + ', establece tu nuevo pin de acceso:'
            this.button = 'Siguiente';
          }
        },
        () => this.mode = Mode.None
      );
    }
    else {
      this.mode = Mode.None;
    }
  }

  protected pin = '';
  protected pinInput = '';
  protected pinError = '';
  protected pinScore: number = 0;
  protected pinsValid() : boolean {
    return (this.pin == '' && this.pinScore > 1) || (this.pin != '' && this.pin == this.pinInput);
  }

  protected updatePin() {
    if (this.pin == '') {
      this.pin = this.pinInput;
      this.pinInput = '';
      this.message = 'Repite tu pin';
      this.button = 'Establecer pin';
      this.shuffle = Math.random();
    }
    else {
      this.ls.updatePin(this.code, +this.pin,
        () => DialogComponent.showInfoDialog('Pin actualizado', 'Su pin se ha actualizado con éxito.')
            .then(_ => this.reset()),
        () => DialogComponent.showError('Su ha producido un error, intentelo de nuevo o contacte con su manager.')
            .then(_ => this.reset())
      );
    }
  }

  protected pinUpdated() {
    if (this.pin == '') {
      if (this.pinInput.length < 4) {
        this.pinError = 'El pin debe tener al menos 4 dígitos';
        this.pinScore = 0;
      }
      else {
        this.pinError = '';
        this.pinScore = 2;
      }
    }
    else if (this.pin != this.pinInput) {
      this.pinError = 'Los pins deben ser iguales';
      this.pinScore = 0;
    }
    else {
      this.pinError = '';
      this.pinScore = 2;
    }
  }

  protected password = '';
  protected passwordError = '';
  protected psswdScore: number = 0;
  protected password2 = '';
  protected passwordsValid() : boolean {
    return this.password != '' && this.password == this.password2 && this.psswdScore > 2;
  }

  protected updatePassword() {
    this.ls.updatePassword(this.code, this.password,
      () => DialogComponent.showInfoDialog('Contraseña actualizada', 'Su contraseña se ha actualizado con éxito.')
          .then(_ => this.reset()),
      () => DialogComponent.showError('Su ha producido un error, intentelo de nuevo o contacte con su manager.')
          .then(_ => this.reset())
    );
  }

  protected passwordUpdated() {
    Zxcvbn.checkPassword(this.password).then(
      result => { 
        this.psswdScore = result.score;
        switch (this.psswdScore) {
        case 0:
        case 1:
          this.passwordError = 'Muy débil  - ' + result.message;
          break;
        case 2:
          this.passwordError = 'Débil  - ' + result.message;
          break;
        default:
          this.passwordError = '';
          break;
        }
      }
    );
  }

  protected reset(error: string = ''): void {
    // don't clear user
    this.password = '';
    this.password2 = '';
    this.pin = '';
    this.pinInput = '';
    this.code = '';
    this.processCode('');
  }
}
