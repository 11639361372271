<img class="logo" src='/img/logo.png' alt='client logo' />
@switch (mode) {
@case (Mode.Loading) {
  <div>Loading...</div>
}
@case (Mode.PinLogin) {
  <div class="pin">
    <span class="title">{{ message }}</span>
    <lbt-pin [(ngModel)]="pinInput" [shuffleKeys]="shuffle" [error]="pinInput != '' && pinScore < 2" errorMessage="{{pinError}}" (ngModelChange)="pinUpdated()"></lbt-pin>
    <lbt-button [disabled]="!pinsValid()" (click)="updatePin()">{{ button }}</lbt-button>
  </div>
}
@case (Mode.PsswdLogin) {
  <div class="password">
    <span class="title">Actualización contraseña para usuario {{ user }}</span>
    <lbt-input type="password" placeholder="Contraseña" [(ngModel)]="password" [strength]="psswdScore" [error]="password != '' && psswdScore < 3" errorMessage="{{passwordError}}" (ngModelChange)="passwordUpdated()"></lbt-input>
    <lbt-input type="password" placeholder="Repita contraseña" [(ngModel)]="password2"  [error]="password2 != '' && password2 != password" errorMessage="La contraseña no coincide"></lbt-input>
    <lbt-button [disabled]="!passwordsValid()" (click)="updatePassword()">Establacer nueva contraseña</lbt-button>
  </div>
}
@case (Mode.None) {
  <div>Código no válido.</div>
}
}